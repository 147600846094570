<template>
  <Modal class-name="i-modal" v-model="isShow" title="编辑订单" width="1200">
    <div class="drawer-container">
      <div class="drawer-content">
        <Divider orientation="left">订单信息</Divider>
        <Row class="text-cell">
          <i-col class="title" span="2">订单编号:</i-col>
          <i-col class="value" span="6">{{ obj.OrderCode }}</i-col>
          <i-col class="title" span="2">会员姓名:</i-col>
          <i-col class="value" span="6">{{ obj.MemberName }}</i-col>
          <i-col class="title" span="2">订单状态:</i-col>
          <i-col span="6">
            <Select
              v-model="obj.OrderStatus"
              :disabled="!$canAction(['Order_Edit'])"
            >
              <Option
                v-for="item in orderStatusEnum"
                :key="item.ID"
                :value="item.ID"
                >{{ item.CN }}</Option
              >
            </Select>
          </i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">收货人名称:</i-col>
          <i-col span="6">
            <Input v-model="obj.ReceiveName" />
          </i-col>
          <i-col class="title" span="2">收货人电话:</i-col>
          <i-col span="6">
            <Input v-model="obj.ReceivePhone" />
          </i-col>
          <i-col class="title" span="2">收货人地址:</i-col>
          <i-col span="6">
            <Input v-model="obj.ReceiveAddress" />
          </i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">物流公司: </i-col>
          <i-col span="6">
            <Select v-model="obj.ExpressCode" placeholder="未发货">
              <Option
                v-for="item in expressInfo"
                :key="item.Code"
                :value="item.Code"
                >{{ item.Name }}</Option
              >
            </Select>
          </i-col>
          <i-col class="title" span="2">物流单号:</i-col>
          <i-col span="6">
            <Input v-model="obj.ShippingCode" placeholder="未发货" />
          </i-col>
          <i-col class="title" span="2">发货时间:</i-col>
          <i-col span="6">
            <DatePicker
              type="datetime"
              style="width: 100%"
              @on-change="obj.ShippingDate = $event"
              :value="obj.ShippingDate"
              placeholder="请选择发货时间"
            ></DatePicker>
          </i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">订单总金额:</i-col>
          <i-col class="value" span="6">{{ obj.TotalAmount }}</i-col>
          <i-col class="title" span="2">实付金额:</i-col>
          <i-col class="value" span="6">{{ obj.PayAmount }}</i-col>
          <i-col class="title" span="2">运费金额:</i-col>
          <i-col class="value" span="6">{{ obj.TotalFreight }}</i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">取消原因:</i-col>
          <i-col class="value" span="6">{{ obj.CancelReason }}</i-col>
          <i-col class="title" span="2">运费类型:</i-col>
          <i-col class="value" span="6">{{ obj.FreightTypeCN }}</i-col>
          <i-col class="title" span="2">订单渠道:</i-col>
          <i-col class="value" span="6">{{ obj.ChannelCN }}</i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">下单时间:</i-col>
          <i-col class="value" span="6">{{ obj.OrderDate }}</i-col>
          <i-col class="title" span="2">取消时间:</i-col>
          <i-col class="value" span="6">{{ obj.CancelDate }}</i-col>
          <i-col class="title" span="2">支付时间:</i-col>
          <i-col class="value" span="6">{{ obj.ConfirmDate }}</i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">完成时间:</i-col>
          <i-col class="value" span="6">{{ obj.FinishDate }}</i-col>
          <i-col class="title" span="2">订单类型:</i-col>
          <i-col class="value" span="6">{{ obj.OrderTypeCN }}</i-col>
          <i-col class="title" span="2">邮编:</i-col>
          <i-col class="value" span="6">{{ obj.PostalCode }}</i-col>
        </Row>
        <Row class="text-cell">
          <i-col class="title" span="2">是否已导单:</i-col>
          <i-col class="value" span="6">{{
            obj.OutStockStatus > 10 ? "是" : "否"
          }}</i-col>
          <i-col class="title" span="2">订单备注:</i-col>
          <i-col span="6">
            <Input v-model="obj.Remark" placeholder="请输入订单备注" />
          </i-col>
          <i-col class="title" span="2">订单系统备注:</i-col>
          <i-col span="6">
            <Input v-model="obj.SysRemark" placeholder="请输入订单系统备注" />
          </i-col>
        </Row>
        <Divider orientation="left">商品信息</Divider>
        <Table
          :columns="productOptions"
          :data="productData"
          max-height="200"
          highlight-row
          size="small"
        >
          <template slot="Image" slot-scope="{ row }">
            <div style="position: relative; width: 32px; height: 32px">
              <Avatar shape="square" :src="$setImagePrefix(row.ProductImg)" />
              <span
                v-if="row.Type === 30"
                style="
                  position: absolute;
                  top: 0;
                  right: -16px;
                  text-align: center;
                  color: rgb(255, 255, 255);
                  background: red;
                  font-size: 12px;
                  padding: 2px 2px;
                "
                >错</span
              >
              <span
                v-if="row.Type === 20"
                style="
                  position: absolute;
                  top: 0;
                  right: -16px;
                  text-align: center;
                  color: rgb(255, 255, 255);
                  background: red;
                  font-size: 12px;
                  padding: 2px 2px;
                "
                >换</span
              >
            </div>
          </template>
        </Table>
        <Divider orientation="left" v-if="ShowPaylog">支付记录</Divider>
        <Table
          :columns="payLogOptions"
          :data="payLogData"
          max-height="200"
          class="refund_table"
          v-if="ShowPaylog"
          highlight-row
          size="small"
        >
          <template slot="action" slot-scope="{ row }">
            <Poptip
              confirm
              title="您确定要退款吗？"
              :loading="loading"
              @on-ok="handleRefundPayLog(row)"
            >
              <Button
                type="primary"
                v-if="
                  $canAction(['Order_RefundPayLog']) &&
                  (row.PayStatus == 2 || row.PayStatus == 4)
                "
                size="small"
                >退款</Button
              >
            </Poptip>
          </template>
        </Table>
        <Divider orientation="left" v-if="ShowRefund">退款记录</Divider>
        <Table
          :columns="refundLogOptions"
          :data="refundLogData"
          max-height="200"
          highlight-row
          v-if="ShowRefund"
          size="small"
        >
        </Table>
      </div>
    </div>
    <template slot="footer">
      <!--        <Button type="error" v-if="obj.OrderStatus !== -99" class="ml2" @click="handleDelete(obj)">删除订单</Button>-->
      <Button @click="isShow = false">取消</Button>
      <Button
        v-if="
          $route.name !== 'ShippingCustomerList' &&
          $route.name !== 'ShippingCustomerList2'
        "
        type="primary"
        class="ml2"
        :loading="loading"
        @click="handleSave()"
        >保存</Button
      >
      <Button
        type="warning"
        v-if="
          $route.name === 'ShippingCustomerList' ||
          $route.name === 'ShippingCustomerList2'
        "
        class="ml2"
        :loading="loading"
        @click="handleShipments(obj)"
        >发货</Button
      >
    </template>
    <cancel-order-modal ref="CancelOrderModal" />
    <return-good-modal ref="ReturnGoodModal" />
    <refund-modal @on-success="isShow = false" ref="RefundModal" />
  </Modal>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import CancelOrderModal from "./cancel-order-modal.vue";
import ReturnGoodModal from "./return-good-modal.vue";
import RefundModal from "./refund-modal.vue";
import { mapActions } from "vuex";
export default {
  name: "detail-drawer",
  data() {
    return {
      isEdit: false,
      isShow: false,
      loading: false,
      obj: {},
      productOptions: [
        { title: "商品图片", key: "ProductImg", slot: "Image", width: 120 },
        { title: "商品ID", key: "ProductID", width: 60 },
        { title: "商品编号", key: "Code", width: 100 },
        { title: "商品名称", key: "ProductName", tooltip: true },
        { title: "商品分类", key: "ClassName", width: 150 },
        { title: "商品单价", key: "UnitPrice", width: 120, align: "center" },
        { title: "商品数量", key: "ProductCount", width: 100, align: "center" },
        { title: "应发数量", key: "ShippingNum", width: 100, align: "center" },
        {
          title: "实发数量",
          key: "RealShippingNum",
          width: 100,
          align: "center",
        },
        { title: "已退数量", key: "ReturnNum", width: 100, align: "center" },
        { title: "损耗数量", key: "LossNum", width: 100, align: "center" },
      ],
      productData: [],
      payLogOptions: [],
      payLogData: [],
      refundLogOptions: [
        { title: "退款时间", key: "RefundDate" },
        { title: "物流费", key: "FreightCost" },
        { title: "手续费", key: "HandleCost" },
        { title: "实退金额", key: "RealRefund" },
        { title: "备注", key: "Remark" },
      ],
      refundLogData: [],
      expressInfo: [],
      ShowPaylog: true,
      ShowRefund: true,
      RefundCostScale: 0.0,
    };
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.getOrderProductList(this.obj.ID);
        this.GetOrderPayLog(this.obj.OrderCode);
        this.GetOrderRefundLog(this.obj.OrderCode);
        // if (this.isEdit) {
        this.GetExpressList();
        // }
      }
    },
  },
  created() {
    this.getProductClassList();
    this.loadConfig();
  },
  methods: {
    ...mapActions(["getProductClassList"]),
    // 获取订单商品
    async getOrderProductList(id) {
      // TODO 接口要调整
      try {
        const res = await api.GetOrderProductList({
          orderid: id,
        });
        res.Data.forEach((item) => {
          item.ClassName = item.ProductClassName;
        });
        this.productData = res.Data;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    // 获取订单支付记录
    async GetOrderPayLog(ordercode) {
      try {
        const res = await api.GetOrderPayLog({
          ordercode: ordercode,
        });
        if (res.Data.length > 0) {
          this.ShowPaylog = true;
        } else {
          this.ShowPaylog = false;
        }
        this.payLogOptions = [
          { title: "支付方式", key: "PayTypeCN", width: 100 },
          { title: "内部流水号", key: "PayID", width: 250 },
          { title: "支付金额", key: "PayMoney", width: 100 },
          { title: "优惠金额", key: "DisCountAmount", width: 100 },
          { title: "支付结果", key: "PayStatusCN", width: 100 },
          { title: "支付时间", key: "PayDate", width: "auto", minWidth: 150 },
          { title: "支付备注", key: "Remark", width: "auto", minWidth: 150 },
        ];
        // 已退款，部分退款时显示数据
        if (
          res.Data.some((item) => item.PayStatus === 3 || item.PayStatus === 4)
        ) {
          this.payLogOptions = this.payLogOptions.concat([
            { title: "可退金额", key: "CanRefund", width: 100 },
            { title: "已退金额", key: "RealRefund", width: 100 },
            // 暂时不显示
            // { title: '物流', key: 'FreightCost', width: 100 },
            // { title: '手续', key: 'HandleCost', width: 100 },
            { title: "实退金额", key: "RealRefund", width: 100 },
            { title: "退款时间", key: "RefundDate", width: 150 },
          ]);
        }
        // 可退款时显示按钮
        if (
          res.Data.some((item) => item.PayStatus === 2 || item.PayStatus === 4)
        ) {
          if (this.$canAction(["Order_RefundPayLog"])) {
            this.payLogOptions = this.payLogOptions.concat([
              {
                title: "操作",
                key: "action",
                slot: "action",
                fixed: "right",
                width: 80,
              },
            ]);
          }
        }
        this.payLogData = res.Data;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    // 获取退款记录
    async GetOrderRefundLog(ordercode) {
      try {
        const res = await api.GetOrderRefundLog({
          ordercode: ordercode,
        });
        this.refundLogData = res.Data;
        if (res.Data.length > 0) {
          this.ShowRefund = true;
        } else {
          this.ShowRefund = false;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    // 获取物流配置信息
    async GetExpressList() {
      try {
        const res = await api.GetExpressList({
          Status: 10,
          PageSize: 100,
        });
        if (res.Data) {
          this.expressInfo = res.Data.DataList;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    // 删除订单
    handleDelete(row) {
      const self = this;
      this.$Modal.confirm({
        title: "删除确认",
        loading: true,
        content: "确定要删除该订单吗?",
        okText: "删除",
        async onOk() {
          try {
            await api.DeleteOrder({ ids: row.ID });
            self.obj.OrderStatus = -99;
            self.obj.StatusCN = "已删除";
            self.$Message.success("删除成功");
            self.$Modal.remove();
          } catch (error) {
            this.$Notice.error({ title: "错误", desc: error });
            this.$Modal.remove();
          }
        },
      });
    },
    // 保存订单
    handleSave() {
      const self = this;
      if (self.obj.ExpressCode) {
        let sname = self.expressInfo.find(
          (item) => item.Code === self.obj.ExpressCode
        ).Name;
        self.obj.ExpressName = sname;
      }
      self.$Modal.confirm({
        title: "保存确认",
        content: "确定要保存修改吗?",
        loading: true,
        onText: "保存",
        async onOk() {
          try {
            self.loading = true;
            await api.SaveOrder({ entity: self.obj });
            self.$Message.success("保存成功");
            self.loading = false;
            self.isShow = false;
            self.$Modal.remove();
            self.isShow = false;
            self.$emit("saveOrderOk");
          } catch (error) {
            this.$Modal.remove();
            self.loading = false;
          }
        },
      });
    },
    async handleRefundPayLog(row) {
      this.$refs.RefundModal.formData.payLogID = row.ID;
      this.$refs.RefundModal.formData.logisticsCost = 0; // 物流费
      this.$refs.RefundModal.formData.handleCost = (
        row.PayType === 4 ? 0 : row.CanRefund * this.RefundCostScale
      ).toFixed(2); // 手续费
      this.$refs.RefundModal.formData.CanRefund = row.CanRefund;
      this.$refs.RefundModal.formData.refundAmount =
        row.CanRefund - this.$refs.RefundModal.formData.handleCost;
      this.$refs.RefundModal.isShow = true;
    },
    async loadConfig() {
      try {
        const res = await api.GetSysConfigByKey({
          key: "RefundCostScale",
        });
        this.RefundCostScale = res.Data.Value;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
      }
    },
    async handleCancel(row) {
      const self = this;
      try {
        // 订单取消前判断
        const res = await api.BeforeCancelOrder({
          orderid: row.ID,
        });
        // 给`退单费用`弹窗赋值
        self.$refs.CancelOrderModal.formData.orderid = self.obj.ID;
        self.$refs.CancelOrderModal.formData.sysRemark = res.Msg;
        self.$refs.CancelOrderModal.formData.logisticsCost = 0;
        self.$refs.CancelOrderModal.propIsDeduction = !(
          row.IsGroupMain && row.OrderType === 60
        );
        self.$refs.CancelOrderModal.OrderType = row.OrderType;
        // 不是奖金支付 ，手续费需扣除

        self.$refs.CancelOrderModal.formData.handleCost =
          self.obj.PayType === 4
            ? 0
            : self.payLogData.find((item) => item.PayStatus === 2).CanRefund *
              this.RefundCostScale;
        self.$refs.CancelOrderModal.formData.canRefund = self.payLogData.find(
          (item) => item.PayStatus === 2
        ).CanRefund;
        self.$refs.CancelOrderModal.formData.realRefund = self.payLogData.find(
          (item) => item.PayStatus === 2
        ).CanRefund;

        if (res.Data) {
          // 接口有返回内容，弹出提示内容
          self.$Modal.confirm({
            title: "取消确认",
            content: res.Data,
            loading: true,
            onOk() {
              self._cancelOrder();
            },
          });
        } else {
          // 取消订单售后流程
          self._cancelOrder();
        }
      } catch (err) {
        this.$Notice.error({ title: "错误", desc: err });
      }
    },
    // 发货
    async handleShipments(row) {
      if (
        row.ExpressCode === "" ||
        row.ShippingCode === "" ||
        row.ShippingDate === ""
      ) {
        this.$Message.error("请填写发货信息");
      } else {
        self.loading = true;
        row.ExpressName = this.expressInfo.find(
          (item) => item.Code === row.ExpressCode
        ).Name;
        await api.SendEMS({ entity: row });
        this.$Message.success("发货成功");
        self.loading = false;
      }
    },
    // 退货
    async handleReturn() {
      this.$refs.ReturnGoodModal.isShow = true;
      this.$refs.ReturnGoodModal.formData.list = this.productData;
    },
    // 取消订单售后流程
    _cancelOrder() {
      this.$Modal.remove();
      this.$refs.CancelOrderModal.isShow = true;
    },
  },
  computed: {
    orderStatusEnum() {
      return bizEnum.OrderStatusEnum;
    },
    productClass() {
      return this.$store.state.product.classList;
    },
  },
  components: { CancelOrderModal, ReturnGoodModal, RefundModal },
};
</script>
<style lang="less">
.refund_table {
  overflow: visible !important;
  .ivu-table-fixed-body {
    overflow: visible !important;
    height: auto !important;
  }
  .ivu-poptip-popper {
    left: -71px !important;
  }
  .ivu-poptip-arrow {
    left: 60% !important;
  }
}
</style>
