<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <i-col span="6">
            <FormItem label="订单编号:" prop="OrderCode">
              <Input
                placeholder="请输入订单编号"
                v-model="params.OrderCode"
                clearable
              />
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem label="会员编号:" prop="MemberCode">
              <Input
                placeholder="请输入会员编号"
                v-model="params.MemberCode"
                clearable
              />
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem label="商品名称:" prop="ProductName">
              <Input
                placeholder="请输入商品名称"
                v-model="params.ProductName"
                clearable
              />
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem label="收货人:" prop="ReceiveSearchText">
              <Input
                placeholder="请输入收货人名称/电话/地址"
                v-model="params.ReceiveSearchText"
                clearable
              />
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="16">
          <i-col span="6">
            <FormItem label="订单状态:" prop="OrderStatus">
              <Select
                clearable
                v-model="params.OrderStatus"
                placeholder="订单状态"
              >
                <Option
                  v-for="(item, index) in orderStatusEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem label="物流单号:" prop="ShippingCode">
              <Input
                placeholder="请输入物流单号"
                v-model="params.ShippingCode"
                clearable
              />
            </FormItem>
          </i-col>
          <!-- <i-col span="6">
            <FormItem label="订单类型:" prop="OrderType">
              <Select
                clearable
                v-model="params.OrderType"
                placeholder="订单类型"
              >
                <Option
                  v-for="(item, index) in orderTypeEnum"
                  :key="index"
                  :value="item.ID"
                  >{{ item.CN }}</Option
                >
              </Select>
            </FormItem>
          </i-col> -->
          <i-col span="12">
            <FormItem label="下单时间:" prop="TempDate">
              <Date-picker
                style="width: 100%"
                clearable
                v-model="params.TempDate"
                ref="date"
                type="datetimerange"
                placement="bottom-end"
                placeholder="下单日期范围"
                format="yyyy-MM-dd HH:mm:ss"
                @on-change="dateUpdate"
              ></Date-picker>
            </FormItem>
          </i-col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <i-col span="12" style="text-align: left">
          <!-- <Button type="success"
                  class="ml2"
                  v-if="$canAction(['Order_BulkCancelOrder'])"
                  @click="batchCancelModel = true"
                  icon="md-apps">批量取消退款</Button> -->
        </i-col>
        <i-col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </i-col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      :row-class-name="RowClassName"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      @on-sort-change="sortChange"
      size="small"
      stripe
    >
      <template slot="MemberView" slot-scope="{ row }">
        <span
          style="color: green; cursor: pointer"
          @click="handleOpenUser(row.MemberCode)"
        >
          {{ row.MemberCode }}
        </span>
      </template>
      <template slot="ShippingCode" slot-scope="{ row }">
        <span>{{ row.ShippingCode || "未发货" }}</span>
      </template>
      <template slot="OrderStatus" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.OrderStatus === 10
              ? 'purple'
              : row.OrderStatus === 30
              ? 'cyan'
              : row.OrderStatus === 40
              ? 'primary'
              : row.OrderStatus === 50
              ? 'warning'
              : row.OrderStatus === 60
              ? 'success'
              : row.OrderStatus === 70
              ? 'red'
              : row.OrderStatus === -99
              ? 'error'
              : 'default'
          "
        >
          {{ row.OrderStatusCN }}
        </Tag>
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="primary"
          v-if="$canAction(['Order_Search'])"
          @click="openDrawer(row)"
        >
          查看
        </Button>
        <Button
          class="ml2"
          size="small"
          type="success"
          v-if="$canAction(['Order_Edit'])"
          @click="openEdit(row)"
        >
          编辑
        </Button>
        <!-- <Button v-if="row.OrderStatus !== -99"
                class="ml2"
                size="small"
                type="error"
                @click="handleCancel(row)">删除</Button> -->
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <i-col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </i-col>
    </Row>
    <detail-drawer @saveOrderOk="saveOrderOk" ref="DetailDrawer" />
    <edit-detail @saveOrderOk="saveOrderOk" ref="EditDetail" />
    <member-drawer ref="memberDrawer" />
  </Layout>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import Layout from "@/components/layout/index.vue";
import DetailDrawer from "./components/detail-drawer.vue";
import MemberDrawer from "../member/components/member-drawer.vue";
import EditDetail from "./components/edit-detail.vue";
import { getStorage, token } from "@/utils/storage";
export default {
  name: "AllOrder",
  data() {
    return {
      TempDate: [],
      batchCancelModel: false,
      batchCancelLoading: false,
      uploadFile: "",
      bulkShipmentForm: {
        ExpressCode: "",
        ExpressName: "",
        ShippingDate: "",
      },
      tableHeight: 0,
      tableLoading: false,
      isSearchMore: false,
      params: {
        ShippingCode: "",
        OrderCode: "",
        MemberCode: "",
        ReceiveSearchText: "",
        ReceiveName: "",
        ReceivePhone: "",
        OrderStatus: "",
        OrderType: "",
        OrderDateBegin: "",
        OrderDateEnd: "",
        CheckTest: 1,
        ProductName: "",
        Page: 1,
        PageSize: 40,
        SortName: "OrderDate",
        SortOrder: "Desc",
      },
      columns: [
        {
          title: "订单编号",
          key: "OrderCode",
          minWidth: 175,
          sortable: "custom",
        },
        // {
        //   title: "会员编号",
        //   key: "MemberCode",
        //   slot: "MemberView",
        //   align: "center",
        //   minWidth: 90,
        //   sortable: "custom",
        // },
        // { title: '会员编号', key: 'MemberCode',  minWidth: 90, sortable: 'custom' },
        {
          title: "总金额",
          key: "TotalAmount",
          minWidth: 80,
          sortable: "custom",
        },
        {
          title: "商品名",
          key: "ProductName",
          minWidth: 130,
          sortable: "custom",
        },
        {
          title: "商品数量",
          key: "ProductCount",
          minWidth: 90,
          sortable: "custom",
        },
        { title: "收货姓名", key: "ReceiveName", minWidth: 95 },
        { title: "收货人电话", key: "ReceivePhone", minWidth: 95 },
        // { title: "订单类型", key: "OrderTypeCN", minWidth: 80 },
        {
          title: "物流单号",
          key: "ShippingCode",
          slot: "ShippingCode",
          minWidth: 120,
          sortable: "custom",
          ellipsis: true,
        },
        {
          title: "状态",
          key: "OrderStatus",
          minWidth: 80,
          slot: "OrderStatus",
          sortable: "custom",
        },
        {
          title: "下单时间",
          key: "OrderDate",
          align: "center",
          minWidth: 135,
          sortable: "custom",
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      // 日期快捷选择
      options: {
        shortcuts: [
          {
            text: "最近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "最近一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "最近三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  watch: {
    batchCancelModel(val) {
      if (!val) {
        this.$refs.upload.clearFiles();
      }
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      let blAdd =
        !this.$canAction(["Order_Edit"]) &&
        this.$canAction(["OrderStore_Edit"]);
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        fixed: "right",
        width: blAdd
          ? this.$route.meta.tableActionWidth + 50
          : this.$route.meta.tableActionWidth,
      });
    }
    this.loadOrderList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.handleSearch();
      }
    });
  },
  activated() {
    document.addEventListener("keydown", this.keydownFun);
  },
  deactivated() {
    document.removeEventListener("keydown", this.keydownFun);
  },
  methods: {
    keydownFun(event) {
      var e = event || window.event;
      if (this.$route.name === this.$options.name && e && e.keyCode === 13) {
        // enter 键
        this.handleSearch();
      }
    },
    saveOrderOk() {
      this.$delayLoad(() => {
        this.loadOrderList();
      });
    },
    beforeUpload(file) {
      this.uploadFile = file;
      return false;
    },
    handleBulkShipment() {
      if (this.uploadFile === "") {
        this.$Message.error("批量取消退款必须上传表格文件");
      } else {
        this.$refs.upload.post(this.uploadFile);
      }
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件大小超出限制",
        desc: `文件${file.name}太大，不能超过2M`,
      });
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc: `文件${file.name}格式不正确，请上传.xls, .xlsx, .xlsb, .xlsm, .xlst, .csv 格式的表格。`,
      });
    },
    uploadSuccess(response) {
      let result = response.Data.str;
      if (response.Data.list.length > 0) {
        result = `${response.Data.str},失败订单号:${response.Data.list.join(
          ","
        )}`;
      }
      this.$Modal.info({
        title: "处理完成",
        content: result,
      });
      this.batchCancelModel = false;
    },
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = result.order !== "normal" ? result.order : "desc";
      this.loadOrderList();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.OrderDateBegin = value[0];
      this.params.OrderDateEnd = value[1];
    },
    handleCancel(row) {
      const self = this;
      this.$Modal.confirm({
        title: "删除确认",
        loading: true,
        content: "确定要删除该订单吗?",
        okText: "删除",
        async onOk() {
          try {
            await api.DeleteOrder({ ids: row.ID });
            const index = self.resObj.DataList.findIndex(
              (item) => item.ID === row.ID
            );
            if (index !== -1) {
              self.resObj.DataList[index].OrderStatus = -99;
              self.resObj.DataList[index].StatusCN = "已删除";
            }
            self.$Message.success("删除成功");
            self.$Modal.remove();
          } catch (error) {
            console.error(error);
            this.$Modal.remove();
          }
        },
      });
    },
    openDrawer(row) {
      this.$refs.DetailDrawer.obj = row;
      this.$refs.DetailDrawer.isShow = true;
    },
    openEdit(row) {
      this.$refs.EditDetail.obj = row;
      this.$refs.EditDetail.isShow = true;
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadOrderList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadOrderList();
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadOrderList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.TempDate = [];
      this.params.OrderDateBegin = "";
      this.params.OrderDateEnd = "";
      this.loadOrderList();
    },
    async handleOpenUser(MemberID) {
      try {
        const res = await api.GetMemberByCode({
          code: MemberID,
        });
        if (res.Status === 100) {
          this.$refs.memberDrawer.obj = res.Data;
          this.$refs.memberDrawer.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 选中行样式
    RowClassName(row) {
      return row.ServiceID ? "demo-table-error-row" : "";
    },
    async loadOrderList() {
      this.tableLoading = true;
      try {
        const res = await api.GetOrderList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (err) {
        this.tableLoading = false;
        console.error(err);
      }
    },
  },
  computed: {
    orderStatusEnum() {
      return bizEnum.OrderStatusEnum;
    },
    orderTypeEnum() {
      return bizEnum.OrderTypeEnum;
    },
    headers() {
      return { Authorization: "Bearer " + getStorage(token) };
    },
  },
  components: { DetailDrawer, EditDetail, MemberDrawer, Layout },
};
</script>

<style lang="less">
.ivu-table .demo-table-error-row td {
  background-color: #ffd591 !important;
  color: rgb(0, 0, 0);
}
</style>
